<template>
  <div class="welcome d-flex align-center justify-center">
    <v-card class="login mb-12">
      <v-card-title class="d-block text-center">Reset password</v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            :error-messages="errors('email')"
            label="E-mail"
            outlined
            dense
            required
          ></v-text-field>

          <p class="red--text text--darken-3">
            {{ formError }}
          </p>

          <v-btn color="primary" block :disabled="!emailPresent || !valid" @click="resetPassword">
            Reset
          </v-btn>

          <v-divider class="my-8"></v-divider>

          <p class="mt-4 text-center">
            <router-link to="/login" class="text-decoration-none">
              Back to login
            </router-link>
          </p>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Validation
      valid: false,
    }
  },

  computed: {
    email: {
      get() { return this.$store.state.user.email },
      set(val) { this.$store.commit('setEmail', val) }
    },

    formError() {
      return this.$store.getters['formError']
    },

    emailRules() {
      return this.$store.state.emailRules
    },

    emailPresent() {
      return this.user.email.length > 0
    },

    resetError() {
      return this.$store.state.resetError
    },

    user() {
      return { email: this.email }
    }
  },

  mounted() {
    this.$store.dispatch('resetError')
  },

  methods: {
    errors(field) {
      return this.$store.getters['fieldError'](field)
    },

    resetPassword() {
      this.$store.dispatch('resetPassword', this.user)
    }
  }
}
</script>
<style scoped>
.welcome {
  height: 100%;
}
.login {
  max-width: 400px;
  width: 100%;
}
</style>
