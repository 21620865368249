<template>
  <v-app class="app">
    <Navigation>
      <v-btn href="/#features">
        Features
      </v-btn>

      <v-btn href="/#pricing">
        Pricing
      </v-btn>

      <v-btn href="/#support">
        Support
      </v-btn>

      <v-btn href="/login" v-if="!loggedIn">
        Login
      </v-btn>

      <v-btn href="/app" v-if="loggedIn">
        My studio
      </v-btn>

      <v-btn href="/logout" v-if="loggedIn">
        Logout
      </v-btn>
    </Navigation>

    <router-view />
  </v-app>
</template>

<script>
import Navigation from '@/shared/Navigation.vue'
import useUser from '@/root/stores/user'

export default {
  components: { Navigation },

  setup() {
    return {
      user: useUser()
    }
  },

  computed: {
    loggedIn() {
      return !!this.user.currentUser?.id
    }
  },
}
</script>

<style lang="scss">
.v-application.app {
  color: #444;

  .text-h3,.text-h4,.text-h6 {
    font-weight: bold;
  }

  .text-h5,.text-light {
    opacity: 0.75;
  }
}
</style>
