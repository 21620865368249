<template>
  <div class="welcome d-flex align-center justify-center pt-16">
    <v-card variant="elevated" elevation="3" class="mb-12 py-3" max-width="400px" width="100%">
      <v-card-title class="d-block text-center mb-3">Log in</v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          validate-on="input"
        >
          <v-text-field
            class="mb-2"
            v-model="email"
            :rules="user.validationRules.email"
            label="E-mail"
            variant="outlined"
            density="compact"
          ></v-text-field>

          <v-text-field
            class="mb-2"
            v-model="password"
            label="Password"
            type="password"
            :rules="user.validationRules.password"
            variant="outlined"
            density="compact"
          ></v-text-field>

          <p v-if="user.error" class="text-red-darken-2 mb-2">
            {{ user.error }}
          </p>

          <v-btn color="primary" block @click="login" :disabled="!loginPresent || !valid">
            Log in
          </v-btn>

          <p class="mt-4 text-center">
            <router-link to="/reset" class="text-decoration-none">
              Forgot password?
            </router-link>
          </p>

          <v-divider class="my-8"></v-divider>

          <p class="mt-4 text-center">
            Don't have an account?
            <br />

            <router-link to="/register" class="text-decoration-none">
              Sign up
            </router-link>
          </p>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import useUser from '@/root/stores/user'

export default {
  setup() {
    return {
      user: useUser()
    }
  },

  data() {
    return {
      // validation
      valid: false,
    }
  },

  computed: {
    email: {
      get() { return this.user.email },
      set(val) { this.user.setEmail(val) }
    },

    password: {
      get() { return this.user.password },
      set(val) { this.user.setPassword(val) }
    },

    loginPresent() {
      return this.email.length > 0 && this.password.length > 0
    }
  },

  mounted() {
    this.user.clearErrors()
  },

  methods: {
    async login() {
      const { email, password } = this
      console.log("LOGIN")
      await this.user.login({ email, password })
      console.log("LOGGED IN")
    },
  }
}
</script>
<style scoped>
.welcome {
  height: 100%;
}
</style>
