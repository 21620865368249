<template>
  <div class="welcome d-flex align-center justify-center pt-16">
    <v-card variant="elevated" elevation="3" class="mb-12 py-3" max-width="400px" width="100%">
      <v-card-title class="d-block text-center mb-3">Sign up</v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" validate-on="input">
          <v-text-field
            class="mb-2"
            v-model="firstName"
            label="First name"
            :rules="validationRules.firstName"
            variant="outlined"
            density="compact"
          ></v-text-field>

          <v-text-field
            class="mb-2"
            v-model="lastName"
            label="Last name"
            :rules="validationRules.lastName"
            variant="outlined"
            density="compact"
          ></v-text-field>

          <v-text-field
            class="mb-2"
            v-model="email"
            :rules="validationRules.email"
            label="E-mail"
            variant="outlined"
            density="compact"
            required
          ></v-text-field>

          <v-text-field
            class="mb-2"
            v-model="password"
            :rules="validationRules.password"
            label="Password"
            type="password"
            variant="outlined"
            density="compact"
          ></v-text-field>

          <p v-if="user.error" class="text-red-darken-2 mb-2">
            {{ user.error }}
          </p>

          <v-btn color="primary" block :disabled="!signupPresent || !valid" @click="register">
            Sign up
          </v-btn>

          <v-divider class="my-8"></v-divider>

          <p class="mt-4 text-center">
            Already have an account?
            <br />

            <router-link to="/login" class="text-decoration-none">
              Log in
            </router-link>
          </p>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import useUser from '@/root/stores/user'

export default {
  setup() {
    return {
      user: useUser()
    }
  },

  data() {
    return {
      // Validation
      valid: false,
    }
  },

  computed: {
    email: {
      get() { return this.user.email },
      set(val) { this.user.setEmail(val) }
    },

    firstName: {
      get() { return this.user.firstName },
      set(val) { this.user.setFirstName(val) }
    },

    lastName: {
      get() { return this.user.lastName },
      set(val) { this.user.setLastName(val) }
    },

    password: {
      get() { return this.user.password },
      set(val) { this.user.setPassword(val) }
    },

    signupPresent() {
      return ['email', 'firstName', 'lastName', 'password']
        .every(attr => this.user[attr].length > 0)
    },

    validationRules() {
      return this.user.validationRules
    }
  },

  mounted() {
    this.user.clearErrors()
  },

  methods: {
    register() {
      this.user.register()
    }
  }
}
</script>
<style scoped>
.welcome {
  height: 100%;
}
</style>
