<template>
  <div>
    <v-container style="margin-top: 100px">
      <v-row
        align="center"
        justify="center"
        class="mt-12 mb-10"
      >
        <h1 class="text-h3 title">Piano teaching you'll love</h1>
      </v-row>

      <v-row class="mt-12 pt-4 pb-2">
        <v-col />
        <v-col cols="8">
          <v-sheet elevation="6" rounded style="overflow: hidden">
            <img src="https://mezetude.s3.amazonaws.com/assets/mezetude-player2.png" width="100%" />
          </v-sheet>
        </v-col>
        <v-col />
      </v-row>
    </v-container>

    <div class="text-center" style="margin: 150px 0 150px 0;">
      <h1 class="text-h3 mb-8 title">Piano is hard</h1>
      <p class="subtitle text-h5 mx-auto" style="max-width: 440px">
        It's hard to play the right keys. It's harder to play in rhythm with
        other musicians. <br />Mezetude can help.
      </p>
    </div>

    <Features />

    <Pricing />

    <Subscribe />

    <Footer />
  </div>
</template>
<script>
import Features from '@/root/components/Features.vue'
import Pricing from '@/root/components/Pricing.vue'
import Subscribe from '@/root/components/Subscribe.vue'
import Footer from '@/root/components/Footer.vue'

export default {
  components: {
    Features,
    Pricing,
    Subscribe,
    Footer,
  }
}
</script>
<style lang="scss" scoped>
.headline {
  margin-top: 80px;
  margin-bottom: 60px;

}
</style>
