<template>
  <footer class="pt-12 pb-4">
    <v-container>
      <v-row>
        <v-spacer />

        <v-col>
          <strong>Mezetude</strong>
          <div class="mt-2"><a href="#features">Features</a></div>
          <div><a href="#pricing">Pricing</a></div>
          <div><a href="#">Mezetude for Mac</a></div>
          <!-- <div><a href="#">Mezetude for Windows</a></div> -->
        </v-col>

        <v-spacer />

        <v-col id="support">
          <strong>Support</strong>
          <div class="mt-2">
            <a href="#" data-bs-toggle="modal" data-bs-target="#support-request">
              Contact us
            </a>
          </div>
        </v-col>

        <v-spacer />
      </v-row>
    </v-container>

    <div class="text-center mt-12">
      Callahan Studios &copy; {{ year }}
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
<style>
footer {
  background: #f5f5f5;
}
</style>
