import { createWebHistory, createRouter } from 'vue-router'

import LandingPage from '@/root/views/LandingPage.vue'
import Login from '@/root/views/Login.vue'
import Register from '@/root/views/Register.vue'
import ResetPassword from '@/root/views/ResetPassword.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'root', component: LandingPage },
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/reset', component: ResetPassword },
  ]
});

// Handles 404 Not found
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    window.location.href = '/404'
  } else {
    next();
  }
});

export default router;
