import { createApp } from 'vue'
import Router from '@/root/routes.js'
import { createPinia } from 'pinia'
import App from '@/root/app.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const app = createApp(App)
const Pinia = createPinia()

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: { mdi },
  }
})

app
  .use(Router)
  .use(Pinia)
  .use(vuetify)
  .mount('#app')
