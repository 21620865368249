<template>
  <div id="pricing" class="mt-16">
    <div class="container text-center">
      <h1 class="text-h3 mb-4">Price</h1>
      <h5 class="text-h5 mx-auto" style="max-width: 440px">
        Gain access to our full program and help support future development
      </h5>
    </div>

    <v-container class="mt-12">
      <v-row>
        <v-col cols="4" class="mt-5">
          <v-sheet elevation="0" class="pa-4">
            <h2 class="text-h4 text-light mb-4">Free</h2>

            <v-table>
              <tbody>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Access to all play modes?</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Collect certificates</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Access to <b>some</b> songs in our library</td>
                </tr>
                <tr class="no-access">
                  <td></td>
                  <td>Save favorites from our song library</td>
                </tr>
                <tr class="no-access">
                  <td></td>
                  <td>Download & print sheet music</td>
                </tr>
                <tr class="no-access">
                  <td></td>
                  <td>Customize your program</td>
                </tr>
                <tr class="no-access">
                  <td></td>
                  <td>View students' certificates & progress</td>
                </tr>
                <tr class="no-access">
                  <td></td>
                  <td>Upload your own scores to add to your program</td>
                </tr>
              </tbody>
            </v-table>

            <div class="pt-4">
              <v-btn block variant="outlined" color="grey-darken-3" href="/register">
                Sign up
              </v-btn>
            </div>
          </v-sheet>
        </v-col>

        <v-col cols="4" class="mt-5">
          <v-sheet elevation="4" class="pa-4" rounded>
            <h2 class="text-h4 text-light mb-4">Student</h2>

            <v-table >
              <tbody>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Access to all play modes</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Collect certificates</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Access to all songs in our library</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Save favorites from our song library</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Download & print sheet music</td>
                </tr>
                <tr class="no-access">
                  <td></td>
                  <td>Customize your program</td>
                </tr>
                <tr class="no-access">
                  <td></td>
                  <td>View students' certificates & progress</td>
                </tr>
                <tr class="no-access">
                  <td></td>
                  <td>Upload your own scores to add to your program</td>
                </tr>
              </tbody>
            </v-table>

            <div class="pt-4">
              <v-btn block variant="outlined" color="grey-darken-3">
                Contact us
              </v-btn>
              <!-- <button type="button" class="form-control btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#student-plan-request">
                Contact us
              </button> -->
            </div>
          </v-sheet>
        </v-col>

        <v-col cols="4" class="mt-5">
          <v-sheet elevation="4" class="pa-4" rounded>
            <h2 class="text-h4 text-light mb-4">Teacher</h2>

            <v-table >
              <tbody>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Access to all play modes</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Collect certificates</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Access to all songs in our library</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Save favorites from our song library</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Download & print sheet music</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Customize your program</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>View students' certificates & progress</td>
                </tr>
                <tr>
                  <td><v-icon color="green" icon="mdi-checkbox-marked-circle-outline"></v-icon></td>
                  <td>Upload your own scores to add to your program</td>
                </tr>
              </tbody>
            </v-table>

            <div class="pt-4">
              <v-btn block variant="outlined" color="grey-darken-3">
                Contact us
              </v-btn>
              <!-- <button type="button" class="form-control btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#teacher-plan-request">
                Contact us
              </button> -->
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss" scoped>
.no-access td {
  opacity: 0.5;
}
</style>
