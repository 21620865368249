<template>
  <section class="feature" id="features">
    <v-container>
      <v-row>
        <v-col cols="6" class="pa-16">
          <h2 class="text-h4 mb-4">Listen to the music</h2>
          <ul>
            <li>Listen to the notes beat by beat</li>
            <li>Watch the notes light up as they're being played to you</li>
            <li>Get a feel for the song you're about to learn</li>
          </ul>
        </v-col>

        <v-col cols="6" class="pa-12">
          <v-sheet elevation="6" rounded style="overflow: hidden">
            <video width="100%" controls="controls" src="https://mezetude.s3.amazonaws.com/assets/listening.mov"></video>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="feature">
    <v-container>
      <v-row>
        <v-col cols="6" class="pa-16">
          <v-sheet elevation="6" rounded style="overflow: hidden">
            <video width="100%" controls="controls" src="https://mezetude.s3.amazonaws.com/assets/listening.mov"></video>
          </v-sheet>
        </v-col>

        <v-col cols="6" class="pa-16">
          <h2 class="text-h4 mb-4">Play the notes</h2>
          <ul>
            <li>Develop your muscle memory for the notes in a song before complicating things with rhythm and accompaniment</li>
            <li>Practice each note one by one and see them light up as you go</li>
            <li>Get instant feedback on notes that were played incorrectly</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="feature">
    <v-container>
      <v-row>
        <v-col cols="6" class="pa-16">
          <h2 class="text-h4 mb-4">Get the rhythm</h2>
          <ul>
            <li>Set the tempo of the metronome to start slow and work up your speed</li>
            <li>Play through the song accompanied by another piano to get a feel for the rhythm</li>
            <li>Build your confidence an ability to play through the whole song</li>
          </ul>
        </v-col>

        <v-col cols="6" class="pa-16">
          <v-sheet elevation="6" rounded style="overflow: hidden">
            <video width="100%" controls="controls" src="https://mezetude.s3.amazonaws.com/assets/listening.mov"></video>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="feature">
    <v-container>
      <v-row>
        <v-col cols="6" class="pa-16">
          <v-sheet elevation="6" rounded style="overflow: hidden">
            <video width="100%" controls="controls" src="https://mezetude.s3.amazonaws.com/assets/listening.mov"></video>
          </v-sheet>
        </v-col>

        <v-col cols="6" class="pa-16">
          <h2 class="text-h4">Play with the band</h2>
          <ul>
            <li>Master a song by playing it with a band of instruments accompanying you</li>
            <li>Get a score and detailed feedback with every performance</li>
            <li>Receive a certificate of completion when you play with the band at the target tempo</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style lang="scss">
.feature {
  &:nth-child(odd) {
    background-color: #f5f5f5;
  }

}
</style>
