<template>
  <div id="subscribe" class="container text-center mt-16 pt-8">
    <h1 class="text-h3">Join our newsletter</h1>
    <h5 class="text-h5 mx-auto" style="max-width: 440px">
      Sign up for tips about learning piano, news about our app, and more.
    </h5>

    <div class="mt-16 pb-12 mx-auto">
      <v-text-field
        style="max-width: 600px"
        class="mx-auto"
        variant="outlined"
        label="Email"
      >
        <template v-slot:append>
          <v-btn variant="elevated" color="primary" class="btn-lg">Subscribe</v-btn>
        </template>
      </v-text-field>
    </div>
  </div>
</template>
